import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Popover, notification, Button, Tooltip } from "antd";
import {
  CheckOutlined,
  MinusOutlined,
  FilePdfFilled,
  InfoCircleOutlined,
  QuestionOutlined,
  CloseOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

import { formatDateTime } from "../../helpers/date";

import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 
import config from "../../config/app"

const downloadReport = (payoutId) => {
  axios
    .post(`/api/payout/${payoutId}/advice-report`, {})
    .then((response) => {
      window.open(response.data.url);
    })
    .catch(async (err) => {
      notification.error({
        message: `Error when downloading Payout Report`,
        description: err.response?.data.message || err.message,
      });
    });
};

const PayoutList = (props) => {
  const { permissions } = usePermissions();

  const [payouts, setPayouts] = useState(props.payouts);
  const [selectedRows, setSelectedRows] = useState(props.selectedRows);

  useEffect(() => {
    setPayouts(props.payouts);
  }, [props.payouts]);

  const table = [
    {
      title: "Payout ID",
      dataIndex: ["objectId"],
    },
    {
      title: "Withdrawal",
      dataIndex: "requestedBy",
      render: (requestedBy) => {
        if (requestedBy) {
          return <CheckOutlined style={{ color: "green" }} />;
        }

        return <span></span>;
      },
    },
    {
      title: "Remark",
      dataIndex: ["remark"],
    },
    {
      title: "Beneficiary Merchant",
      dataIndex: ["user"],
      render: (user, payout) => {
        const bankDetails = (
          <p>
            <strong>Bank</strong>: {payout?.payload?.bankName}
            <br />
            <strong>Bank Account</strong>: {payout?.payload?.accountNumber}
            <br />
            <strong>Account Name</strong>: {payout?.payload?.accountName}
            <br />
          </p>
        );

        return (
          <>
            {`${user.countryCode || ""}${user.phone || ""} (${
              user.email || ""
            }) `}
            <Popover content={bankDetails}>
              <InfoCircleOutlined />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Requested Total (RM)",
      dataIndex: "requestAmount",
      render: (requestedBy, payout) => {
        if (!requestedBy) {
          return "-"
        }

        const earlyCharge = -(payout.requestAmount * (config.earlyWithdrawalRate / 100))
        const totalDetails = (
          <p>
            <strong>COD Total</strong>: {payout.requestAmount.toFixed(2)}
            <br />
            <strong>Early Withdrawal Charge</strong>: {earlyCharge.toFixed(2)}
          </p>
        );

        return (
          <>
            {(payout.requestAmount + earlyCharge).toFixed(2)} ({payout.requestOrderIds.length}{" "}
            Orders){` `}
            <Popover content={totalDetails}>
              <InfoCircleOutlined />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Total (RM)",
      dataIndex: "amount",
      render: (amount, payout) => {
        if (!amount) {
          return "-"
        }

        const totalDetails = (
          <p>
            <strong>COD Total</strong>: {amount}
            <br />
            <strong>Early Withdrawal Charge</strong>: {payout?.earlyChargeValue || "-"}
            <br />
            <strong>Adjustment</strong>: {payout?.adjustmentValue || "-"}
            <br />
          </p>
        );

        return (
          <>
            {(payout.grandTotal || amount || 0).toFixed(2)} ({payout.orderIds.length}{" "}
            Orders){` `}
            <Popover content={totalDetails}>
              <InfoCircleOutlined />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      render: (createdAt) => formatDateTime(createdAt),
    },
    {
      title: "Success Date",
      dataIndex: "successAt",
      render: (text) => text ? formatDateTime(text) : "-",
    },
    {
      title: "Status",
      dataIndex: "successAt",
      render: (successAt, payout) => {
        if (successAt) {
          return <CheckOutlined style={{ color: "green" }} />;
        }

        if (payout.error) {
          return (
            <Tooltip title={payout.error}>
              <CloseOutlined style={{ color: "red" }} />
            </Tooltip>
          );
        }

        return <MinusOutlined style={{ color: "red" }} />;
      },
    },
    {
      title: "Email",
      dataIndex: "isEmailSent",
      render: (isEmailSent) => {
        if (isEmailSent) {
          return <CheckOutlined style={{ color: "green" }} />;
        }

        return <MinusOutlined style={{ color: "red" }} />;
      },
    },
    {
      title: "Transaction Number",
      dataIndex: "ref_no",
    },
    ...(permissions?.includes(PERMISSIONS.DOWNLOAD_PAYMENT_ADVICE)
      ? [
          {
            title: "Action",
            dataIndex: "objectId",
            render: (id, payout) => {
              if (payout.successAt) {
                return (
                  <Tooltip title="Payment Advice">
                    <Button onClick={() => downloadReport(id)}>
                      <FilePdfFilled />
                    </Button>
                  </Tooltip>
                );
              }

              if (!payout.successAt && !payout.error && !payout.requestedBy) {
                return (
                  <Tooltip title="Check Status">
                    <a target="_blank" href={`/payout/status/${id}`}>
                      <Button>
                        <QuestionOutlined />
                      </Button>
                    </a>
                  </Tooltip>
                );
              }

              if (!payout.successAt && !payout.error && payout.requestedBy) {
                return (
                  <Tooltip title="Process Withdrawal">
                    <a target="_blank" href={`/payout/withdrawal/${id}`}>
                      <Button>
                        <PlayCircleOutlined />
                      </Button>
                    </a>
                  </Tooltip>
                );
              }
            },
          },
        ]
      : []),
  ];

  return (
    <div className="table">
      <Table
        rowSelection={{ selectedRows, onChange: props.onSelectRows }}
        rowKey="objectId"
        className="table-section"
        columns={table}
        dataSource={payouts}
        pagination={false}
        scroll={{ x: true }}
        loading={props.loading}
      />
    </div>
  );
};

export default PayoutList;
