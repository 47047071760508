import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Card,
  Button,
  Row,
  Col,
  Collapse,
  Form,
  InputNumber,
  Input,
  message,
  Checkbox,
} from "antd";
import axios from "axios";

import {
  CaretRightOutlined,
  LockOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import DragUpload from "../Form/DragUpload";
import config from "../../config/app"

const PayoutForm = ({ withdrawalId }) => {
  const [checkedPayouts, setCheckedPayouts] = useState([]);
  const [selectedPayouts, setSelectedPayouts] = useState({})
  const [triggeredPayouts, setTriggeredPayouts] = useState([]);
  const [remark, setRemark] = useState(null);

  const notFoundPayouts = checkedPayouts.find(
    (checkedPayout) => checkedPayout.merchant === null
  )?.data;
  const readyPayouts = checkedPayouts
    .filter((checkedPayout) => checkedPayout.merchant)
    .filter(
      (checkedPayout) =>
        !triggeredPayouts.includes(checkedPayout.merchant?.objectId)
    );

  const payoutAll = () => {
    const confirmationMessage = (
      <div>
        <p>
          <strong>
            After clicking confirm, will create {readyPayouts.length} Payout
            Transactions to following Merchants:
          </strong>
        </p>
        <ol>
          {readyPayouts.map((readyPayout) => (
            <li>
              {readyPayout.merchant.user.email}: RM {readyPayout.isReadyAmount}
            </li>
          ))}
        </ol>
      </div>
    );

    Modal.confirm({
      content: confirmationMessage,
      title: `Are you sure to proceed with Payout ALL?`,
      okText: `Payout RM ${readyPayouts
        .reduce((total, readyPayout) => total + readyPayout.isReadyAmount, 0)
        .toFixed(2)}`,
      onOk() {
        setTriggeredPayouts(
          readyPayouts.map((readyPayout) => readyPayout.merchant.objectId)
        );

        const payoutPromises = readyPayouts.map(async (readyPayout) => {
          await axios.post("/api/payout/pay", {
            orderIds: readyPayout.data
              .filter((order) => order.isReady)
              .map((order) => order.id),
            merchantId: readyPayout.merchant.objectId,
            amount: readyPayout.isReadyAmount,
            remark,
            withdrawalId,
          });
        });

        Promise.all(payoutPromises).then(() => {
          message.info(
            `Bulk Payouts are being processed. Please check the Payout Status after some time.`
          );

          setCheckedPayouts([]);
          setRemark(null);
        });
      },
    });
  };

  const payoutOrders = (merchant, orderIds, codAmount, adjustment) => {
    if (adjustment.adjustmentValue && !adjustment.adjustmentRemark) {
      Modal.error({
        title: "Unable to proceed",
        content: "Adjustment Remark is required.",
      });
      return;
    }

    if (
      !merchant.bankName ||
      !merchant.bankAccount ||
      !merchant.bankHolderName
    ) {
      Modal.error({
        title: "Unable to proceed",
        content: "Merchant has insufficient bank details.",
      });
      return;
    }

    let earlyChargeValue
    if (withdrawalId) {
      earlyChargeValue = -(codAmount * (config.earlyWithdrawalRate / 100))
    }

    const grandTotal = (codAmount + (adjustment.adjustmentValue || 0) + + (earlyChargeValue || 0)).toFixed(
      2
    );

    const confirmationMessage = (
      <div>
        Payout to: <strong>{merchant.bankHolderName}</strong>
        <br />
        Bank: <strong>{merchant.bankName}</strong>
        <br />
        Account No.: <strong>{merchant.bankAccount}</strong>
        <br />
        Remark: <strong>{remark}</strong>
        <br />
        <br />
        <p>
          <strong>
            COD Total: RM {codAmount.toFixed(2)}
            <br />
            <span>
              Adjustment:{" "}
              <strong>RM {adjustment.adjustmentValue?.toFixed(2) || 0}</strong>
              <br />
              Adjustment Remark:{" "}
              <strong>{adjustment.adjustmentRemark || ""}</strong>
              <br />
            </span>
            {!!earlyChargeValue && withdrawalId && (
              <span>
                Early Withdrawal Charge:{" "}
                <strong>{earlyChargeValue ? earlyChargeValue.toFixed(2) : "-"}</strong>
                <br />
              </span>
            )}
            TOTAL: RM {grandTotal}
          </strong>
        </p>
      </div>
    );

    Modal.confirm({
      content: confirmationMessage,
      title: `Are you sure to proceed with Payout?`,
      okText: `Payout RM ${grandTotal}`,
      onOk() {
        setTriggeredPayouts((prev) => [...prev, merchant.objectId]);

        axios
          .post("/api/payout/pay", {
            orderIds,
            merchantId: merchant.objectId,
            amount: codAmount,
            remark,
            withdrawalId,
            ...adjustment,
          })
          .then((res) => {
            message.info(
              `Payout are being processed. Please check the Payout Status after some time.`
            );
          })
          .catch((err) => {
            Modal.error({
              title: `Error when requesting Payout`,
              content: err.response?.data?.message || err.message,
            });
          });
      },
    });
  };

  const setDefaultAll = useCallback(async (checkedPayouts) => {
    const selectedPayouts = {}
        
    checkedPayouts
      .filter(merchantPayouts => !!merchantPayouts.merchant)
      .forEach(merchantPayouts => {
        selectedPayouts[merchantPayouts.merchant.objectId] = merchantPayouts
          .data
          .filter(payout => payout.isReady === true)
          .map(payout => payout.id)
      })

    setSelectedPayouts(selectedPayouts)
  }, [])
  
  const handleUpload = (res) => {
    setTriggeredPayouts([]);
    setCheckedPayouts(res.data.data);
    setDefaultAll(res.data.data)
  };

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/payout/${withdrawalId}/process-withdrawal`)
      .then(res => {
        const checkedPayouts = res.data.data

        setCheckedPayouts(checkedPayouts);
        setDefaultAll(checkedPayouts)
      })
  }, [withdrawalId, setDefaultAll]);

  const totals = {}
  Object.keys(selectedPayouts).forEach(merchantId => {
    const merchantPayouts = checkedPayouts.find(checked => checked.merchant.objectId === merchantId)
    totals[merchantId] = merchantPayouts
      .data
      .filter(payout => selectedPayouts[merchantId].includes(payout.id))
      .reduce((total, payout) => total + payout.cod, 0)
  })

  return (
    <div>
      {checkedPayouts.length > 0 && (
        <Collapse
          className="mt-2"
          size="small"
          defaultActiveKey={[
            ...(notFoundPayouts ? ["notFound"] : []),
            ...readyPayouts
              .map((readyPayout) => readyPayout.merchant?.objectId)
              .slice(0, 5),
            "ALL",
          ]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          {notFoundPayouts.length > 0 && (
            <Collapse.Panel header="Payout: NOT FOUND" key="notFound">
              <h4 style={{ color: "red" }}>
                <strong>
                  <CloseCircleFilled /> Unable to proceed
                </strong>
              </h4>
              <ol>
                {notFoundPayouts.map((order) => (
                  <li key={order.consign_no}>
                    {order.consign_no}: {order.message}
                  </li>
                ))}
              </ol>
            </Collapse.Panel>
          )}

          {readyPayouts.map((readyPayout, index) => {
            const readyOrders = readyPayout.data.filter(
              (order) => order.isReady
            );

            return (
              <Collapse.Panel
                header={`Payout ${index + 1}: ${
                  readyPayout.merchant?.user?.email || ""
                } (${readyPayout.merchant.bankHolderName || "-"})`}
                key={readyPayout.merchant?.objectId}
              >
                <h4 style={{ color: "green" }}>
                  <strong>
                    <CheckCircleFilled /> Ready (Total: RM{" "}
                    {totals[readyPayout.merchant?.objectId]?.toFixed(2)})
                  </strong>
                </h4>
                <ol>
                  {readyOrders.map((order) => (
                    <li key={order.consign_no}>
                      { withdrawalId && <Checkbox 
                        checked={selectedPayouts[readyPayout.merchant?.objectId]?.includes(order.id)} 
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedPayouts(prev => {
                              return {
                                ...prev,
                                [readyPayout.merchant?.objectId]: [...new Set([...prev[readyPayout.merchant?.objectId], order.id])]
                              }
                            })
                          } else {
                            setSelectedPayouts(prev => {
                              return {
                                ...prev,
                                [readyPayout.merchant?.objectId]: prev[readyPayout.merchant?.objectId].filter(payout => payout !== order.id)
                              }
                            })
                          }
                        }}>
                      </Checkbox>}{` `}
                      {order.consign_no}: RM {order.cod?.toFixed(2)}{" "}
                      {order.currency ? `(from ${order.currency})` : ""}
                    </li>
                  ))}
                  { withdrawalId &&
                    <li key="early-charge">Early Withdrawal Charge: -RM {(totals[readyPayout.merchant?.objectId] * (config.earlyWithdrawalRate / 100))?.toFixed(2)}</li>
                  }
                </ol>

                {readyPayout.isReadyAmount > 0 && (
                  <Row justify="center">
                    <Col span="8">
                      <Card>
                        <Form
                          layout="vertical"
                          name={`payout-form-${readyPayout.merchant?.objectId}`}
                          onFinish={(formValues) =>
                            payoutOrders(
                              readyPayout.merchant,
                              selectedPayouts[readyPayout.merchant?.objectId],
                              totals[readyPayout.merchant?.objectId],
                              formValues
                            )
                          }
                        >
                          {withdrawalId &&
                            <Form.Item
                              label="Remark (Required)"
                              name="remark"
                            >
                              <Input
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                              />
                            </Form.Item>
                          }
                          <Form.Item
                            label="Adjustment (RM)"
                            name="adjustmentValue"
                          >
                            <InputNumber
                              placeholder="Optional"
                              step={0.01}
                              max={0}
                              min={-totals[readyPayout.merchant?.objectId]}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Adjustment Remark"
                            name="adjustmentRemark"
                          >
                            <Input.TextArea
                              rows={3}
                              placeholder="Required if there is adjustment"
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              size="large"
                              type="primary"
                              disabled={!remark}
                              htmlType="submit"
                            >
                              <LockOutlined /> PAYOUT
                            </Button>
                          </Form.Item>
                        </Form>
                      </Card>
                    </Col>
                  </Row>
                )}

                <h4 style={{ color: "red" }}>
                  <strong>
                    <CloseCircleFilled /> Unable to proceed
                  </strong>
                </h4>
                <ol>
                  {readyPayout.data
                    .filter((order) => !order.isReady)
                    .map((order) => (
                      <li key={order.consign_no}>
                        {order.consign_no}: {order.message}
                      </li>
                    ))}
                </ol>
              </Collapse.Panel>
            );
          })}

          {!withdrawalId && 
            <Collapse.Panel header="Payout ALL" key="ALL">
              <h4 style={{ color: "green" }}>
                <strong>
                  <CheckCircleFilled /> Ready (Total of ALL Payout: RM{" "}
                  {readyPayouts
                    .reduce(
                      (total, readyPayout) => total + readyPayout.isReadyAmount,
                      0
                    )
                    .toFixed(2)}
                  )
                </strong>
              </h4>
              <ol>
                {readyPayouts.map((payout, index) => (
                  <li key={payout.merchant?.objectId}>
                    Payout {index + 1}: {payout.merchant?.user?.email}: RM{" "}
                    {payout.isReadyAmount?.toFixed(2)}
                  </li>
                ))}

                <div className="text-center">
                  <Button size="large" type="primary" onClick={payoutAll}>
                    <LockOutlined /> PAYOUT ALL <LockOutlined />
                  </Button>
                </div>
              </ol>
            </Collapse.Panel>
          }
        </Collapse>
      )}

      {checkedPayouts.length === 0 && !withdrawalId && (
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="mt-2"
          size="small"
          defaultActiveKey={["upload-form"]}
        >
          <Collapse.Panel header="Upload" key="upload-form">
            <div className="mb-2">
              <Input
                addonBefore="Remark (Required)"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>

            <DragUpload
              disabled={!remark}
              action={`${process.env.REACT_APP_SERVER_URL}/api/payout/process-bulk`}
              accept=".xls, .xlsx"
              method="post"
              onUploadSuccess={(res) => handleUpload(res)}
              additionalDesc={`Excel file with "Payout Tracking Number" header`}
            />
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  );
};

export default PayoutForm;
