import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "antd";

import PayoutForm from "../Remittance/PayoutForm";

const PayoutWithdrawal = () => {
  const { withdrawalId } = useParams();

  return (
    <div>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Withdrawal</h2>
          </Col>
        </Row>
      </div>

      <PayoutForm withdrawalId={withdrawalId} />
    </div>
  );
};

export default PayoutWithdrawal;
