import React, { useEffect, useState, useCallback } from "react"
import axios from 'axios'
import { Col, Row, Breadcrumb, Collapse, Space, Tabs, Table, Spin } from "antd"
import { CaretRightOutlined } from '@ant-design/icons'
import { startCase } from "lodash"

import DragUpload from "../Form/DragUpload"
import { getTopupPackage } from '../../utils/topupPackage'

import { PERMISSIONS } from "../../constants/roles";
import { usePermissions } from "../App/PermissionContext"; 

const columnTypes = {
  prices: ["region", "state", "minWeight", "maxWeight", "dhl", "citylink", "jnt", "poslaju", "ninjavan", "teleport", "flash","best","lineclear","imile","spx"],
  international: ["region", "state", "minWeight", "maxWeight", "teleport", "sfeconomy", "sfstandard", "ninjavan","aramex","redly"],
};

const TopupPackagePricing = () => {
  const { permissions } = usePermissions();
  const routes = [
    { path: "", breadcrumbName: "App" },
    { path: "/topup-package/pricing", breadcrumbName: "Topup Package Pricing" },
  ];

  const [isLoading, setIsLoading] = useState(false)
  const [availablePackages, setAvailablePackages] = useState([])
  const [packagePricings, setPackagePricings] = useState({})
  const [activeTab, setActiveTab] = useState(null)

  const fetchPackagePricings = useCallback(async () => {
    if (!activeTab || packagePricings[activeTab]) { 
      return 
    }

    setIsLoading(true)
    const res = await axios.get(`/api/packages/pricings`, { params: { package: activeTab } })

    setPackagePricings(prevPackagePricings => ({
      ...prevPackagePricings,
      [activeTab]: res.data.data
    }))

    setIsLoading(false)
  }, [activeTab])

  const handleUploadSuccess = () => {
    setPackagePricings({})
    setActiveTab("normal")
    fetchPackagePricings()
  }

  useEffect(() => {
    const getAvailablePackages = async () => {
      const response = await axios.get(`/api/topup-packages`)
      const packages = response.data.meta?.packages || []
      const internationalPackages = response.data.meta?.internationalPackages || []

      setAvailablePackages([...packages, ...internationalPackages])
      setActiveTab("normal")
    }

    getAvailablePackages()
  }, [])
  useEffect(() => { fetchPackagePricings() }, [activeTab, fetchPackagePricings])

  return (
    <div>
      <div className="header">
        <Row>
          <Col span={12}>
            <h2 className="detail-header-text">Topup Package Pricing</h2>
          </Col>
          <Col span={12}>
            <Breadcrumb
              style={{ margin: "16px 0", textAlign: "right" }}
              routes={routes}
            />
          </Col>
        </Row>
      </div>

      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      {permissions?.includes(PERMISSIONS.UPLOAD_PRICING) && (
          <div className="upload">
            <Collapse
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Collapse.Panel header="Upload New Pricing" key="upload">
                <DragUpload
                  action={`${process.env.REACT_APP_SERVER_URL}/api/packages/pricing`}
                  accept=".xls, .xlsx"
                  method="put"
                  onUploadSuccess={handleUploadSuccess}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        )}
        
        <div className="table">
          <Tabs activeKey={activeTab} animated={true} onTabClick={tab => setActiveTab(tab)}>
            { availablePackages.length && availablePackages.map(type => (
              <Tabs.TabPane tab={getTopupPackage(type).label} key={type}>
                <Table
                  rowKey="Id"
                  className="table-section"
                  columns={
                    columnTypes[activeTab?.startsWith('international') ? 'international' : 'prices']
                      .map(column => ({ title: startCase(column), dataIndex: column }))
                  }
                  dataSource={packagePricings[type]}
                  loading={isLoading}
                  pagination={{ pageSize: 50 }}
                />
              </Tabs.TabPane>
            )) }
          </Tabs>

          { !availablePackages.length && <Spin size="large" /> }
        </div>
      </Space>
    </div>
  )
}

export default TopupPackagePricing;